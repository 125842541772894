/*------------------------
    Error Styles  
-------------------------*/
.edu-error-style {
    .content {
        .title {
            margin-bottom: 20px;
        }

        .description {
            margin-bottom: 40px;
        }
    }

    padding-bottom: 130px;

    @media #{$small-tablet} {
        padding-bottom: 80px;
    }
}




.circle-image {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid var(--color-secondary);
        pointer-events: none;
        animation: eduvibe-rotate-animate 13s linear infinite;

        &:nth-child(1) {
            border-radius: 38% 62% 64% 36% / 43% 35% 65% 57%;
        }

        &:nth-child(2) {
            animation-direction: reverse;
            border-radius: 41% 59% 40% 60% / 65% 66% 34% 35%;
        }

        &:nth-child(3) {
            animation-duration: 3s;
            border-radius: 73% 27% 56% 44% / 57% 74% 26% 43%;
        }
    }
}


@keyframes eduvibe-rotate-animate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}